import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../layouts/MainLayout'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../pages/HomePage')
      },
      {
        path: '/agility',
        name: 'test-agility',
        component: () => import('../pages/tests/AgilityPage')
      },
      {
        path: '/change-of-direction',
        name: 'test-change-of-direction',
        component: () => import('../pages/tests/ChangeOfDirectionPage')
      },
      {
        path: '/endurance',
        name: 'test-endurance',
        component: () => import('../pages/tests/EndurancePage')
      },
      {
        path: '/passing',
        name: 'test-passing',
        component: () => import('../pages/tests/PassingPage')
      },
      {
        path: '/dribbling',
        name: 'test-dribbling',
        component: () => import('../pages/tests/DribblingPage')
      },
      {
        path: '/speed',
        name: 'test-speed',
        component: () => import('../pages/tests/SpeedPage')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
