<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

#app {
  position: absolute;
  height: 100%;
  width: 100%;
}

html,
body {
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px;

  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.breadcrumb-item a, .breadcrumb-item a:hover, .breadcrumb-item a:focus {
  color: var(--bs-breadcrumb-item-active-color);
  font-size: 1rem;
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #f6931d !important;
  font-weight: 500;
}
</style>
